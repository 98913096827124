import axios from "./index";

export default {
  login(username, password) {
    let formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    return axios.post("/auth/signin", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  resetPassword(data) {
    return axios.post("/auth/reset_password", data);
  },
};
